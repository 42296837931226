// 
// _pagination.scss
// 

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
    }
  }

  
.page-item.disabled 
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.page-item.active .page-link {
  background: $primary;
  border-color: $primary;
  
}


/*-- Costum --*/

.pagination{
  .page-link {
    color: $gray-600;
    &:focus{
      box-shadow: 0 0 0 0.15rem rgba($primary,.35);
      background-color: rgba($primary,.15);
    }
  }
  .page-item.disabled{
    .page-link {
      color: $gray-500;
    }
  }
}

.custom-pagination {
  .page-link {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 24px;
    text-align: center;
    color: $gray-600;
    &:focus{
      box-shadow: 0 0 0 0.15rem rgba($primary,.35);
      background-color: rgba($primary,.15);
    }
  }
  .active .page-link{
    background-color: rgba($primary,0.15);
    border:1px solid rgba($primary,0.5);
    color: $primary;
    border-radius: 50%;
  }
}

.custom-pagination2 {
  li{
    vertical-align: middle;
  }
  span{   
    color: $primary;
    background-color: rgba($primary,0.15);
    border:1px solid rgba($primary,0.5);
    width: 36px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border-radius: 8%;
}
}


@media (max-width: 620px){
  .pagination-lg .page-link {
      padding: .25rem .5rem;
      font-size: 15px;
  }
}